import {
    IGateway,
    JobIdDto,
    CreateGatewayResult,
    MaybeAssociatedClientDevice,
    GetGatewayInstallerURL,
} from "../../../shared/src/types";
import { apiDelete, apiGet, apiPost } from "./Apis-wrapper";
import { config } from "../config"; // use is later whe work with production

/**
 * Class representing API endpoints for interacting with Zeus.
 *
 * Zeus is a backend service responsible for handling authentication
 * and authorization logic. It provides various APIs for interacting
 * with the server.
 *
 * For more details, refer to the Zeus repository documentation:
 * [https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md]
 *
 */

export default class APIEndpoints {
    private static readonly ZEUS_API_URL: string = config.ZEUS_API_URL || "http://localhost:3400";
    public static readonly TEST_URL: string = `${APIEndpoints.ZEUS_API_URL}/api`;
    public static readonly GATEWAYS_LIST: string = `${APIEndpoints.ZEUS_API_URL}/api/public/gateways`;
    public static readonly CLIENT_DEVICES: string = `${APIEndpoints.ZEUS_API_URL}/api/public/client-devices`;
    public static readonly CREATE_GATEWAY: string = `${APIEndpoints.ZEUS_API_URL}/api/public/gateways`;
    public static readonly REQUEST_GATEWAY_INSTALLER: string = `${APIEndpoints.ZEUS_API_URL}/api/public/hyperion`;
    public static readonly GET_GATEWAY_INSTALLER_STATUS: string = `${APIEndpoints.ZEUS_API_URL}/api/public/hyperion`;
    public static readonly REMOVE_GATEWAY: string = `${APIEndpoints.ZEUS_API_URL}/api/public/gateways`;
    public static readonly REMOVE_CLIENT: string = `${APIEndpoints.ZEUS_API_URL}/api/public/client-devices`;
}

export function buildQueryParams(printerSerial?: string, coreDeviceThingName?: string): string {
    const params = new URLSearchParams({
        ...(coreDeviceThingName?.trim() && { coreDeviceThingName: coreDeviceThingName.trim() }),
        ...(printerSerial?.trim() && { printerSerial: printerSerial.trim() }),
    });

    const queryString = params.toString();
    return queryString ? `?${queryString}` : "";
}

async function handleApiRequest<T>(
    requestFn: () => Promise<T>,
    errorMessagePrefix: string
): Promise<T> {
    try {
        const response = await requestFn();
        return response;
    } catch (error) {
        let errorMessage = "An error occurred while processing the request.";
        let statusCode: any;
        if (error instanceof Error) {
            errorMessage = error?.message || errorMessage;
        } else if (typeof error === "string") {
            errorMessage = error;
        } else if (error instanceof TypeError) {
            errorMessage = "Network error. Please check your internet connection.";
        } else if (error instanceof Response) {
            statusCode = error?.status;
            try {
                errorMessage = await error.text();
            } catch (textError: any) {
                errorMessage = `Error retrieving error message: ${textError?.message}`;
            }
        }

        if (!!statusCode) {
            errorMessage = JSON.stringify({
                message: errorMessage,
                statusCode,
                errorMessagePrefix,
            });
        } else {
            errorMessage = JSON.stringify({ message: errorMessage, errorMessagePrefix });
        }

        throw error;
    }
}
/**
 * Retrieves a list of gateways. Optionally filters the list based on the provided printer serial.
 *
 * @param printerSerial - An optional query parameter. If provided, it is assumed that the request is made by a reseller, and authorization is performed accordingly. See https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#reseller-vs-customer for full documentation.
 * @returns A promise that resolves to an array of IGateway objects.
 * @throws Will throw an error if the API request fails.
 */
export async function getGatewayList(printerSerial?: string): Promise<IGateway[]> {
    const errorMessagePrefix = "Failed to get gateways list";

    // Construct query parameters if printerSerial is provided
    const queryParams = buildQueryParams(printerSerial);

    const ListGatewaysUrl = `${APIEndpoints.GATEWAYS_LIST}${queryParams}`;
    const requestFn = async () => {
        return await apiGet<IGateway[]>(ListGatewaysUrl);
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

/**
 * Retrieves a list of printers. Optionally filters the list based on the provided printer serial.
 *
 * @param printerSerial - An optional query parameter. If provided, it is assumed that the request is made by a reseller, and authorization is performed accordingly. See https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#reseller-vs-customer for full documentation.
 * @returns A promise that resolves to an array of MaybeAssociatedClientDevice objects.
 * @throws Will throw an error if the API request fails.
 */
export async function getOrphanedClients(
    printerSerial?: string
): Promise<MaybeAssociatedClientDevice[]> {
    const errorMessagePrefix = "Failed to get printer list";

    // Construct query parameters if printerSerial is provided
    const queryParams = buildQueryParams(printerSerial);

    const OrphanedClientsUrl = `${APIEndpoints.CLIENT_DEVICES}${queryParams}`;
    const requestFn = async () => {
        return await apiGet<MaybeAssociatedClientDevice[]>(OrphanedClientsUrl);
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

/**
 * Retrieves a Gateways. Optionally filters the list based on the provided printer serial.
 * @param coreDeviceThingName - The name of the gateway to get installer url .
 * @param printerSerial - An optional query parameter. If provided, it is assumed that the request is made by a reseller, and authorization is performed accordingly. See https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#reseller-vs-customer for full documentation.
 * @returns A promise that resolves to Gateway status and URL(if gateway creation status is COMPLETED).
 * @throws Will throw an error if the API request fails.
 */
export async function getGatewayInstallerUrl(
    coreDeviceThingName: string,
    printerSerial?: string
): Promise<GetGatewayInstallerURL> {
    const errorMessagePrefix = "Failed to get gateway installer URL";

    // Construct query parameters if printerSerial is provided
    const queryParams = buildQueryParams(printerSerial);

    const requestFn = async () => {
        const url = `${APIEndpoints.GET_GATEWAY_INSTALLER_STATUS}/${coreDeviceThingName}${queryParams}`;
        return await apiGet<GetGatewayInstallerURL>(url);
    };

    return handleApiRequest(requestFn, errorMessagePrefix);
}

/**
 * Creates a gateway for a client when the printer serial is not provided. If the printer serial is provided, the gateway is associated with the respective client.
 * This API call also handles gateway creation by CSEs and resellers for an associated company, with the association identified using the printer serial by Zeus.
 * @param site - The name of the gateway region.
 * @param region - The name of the gateway site.
 * @param printerSerial - An optional query parameter. If provided, it is assumed that the request is made by a reseller, and authorization is performed accordingly. See https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#reseller-vs-customer for full documentation.
 * @returns A promise that resolves to Creation of gateway based on two conditions
 *          1. A client creating a gateway for their own company.
 *          2. A reseller/CSE creating a gateway for a client/customer using the printer serial in the parameters.
 * @throws Will throw an error if the API request fails.
 */
export async function createGateway(
    site: string,
    region: string,
    printerSerial?: string
): Promise<CreateGatewayResult> {
    const errorMessagePrefix = "Failed to create gateway";
    // Construct query parameters if printerSerial is provided
    const queryParams = buildQueryParams(printerSerial);

    const requestFn = async () => {
        const payload = {
            site: site.trim(),
            region: region.trim(),
        };
        const url = `${APIEndpoints.CREATE_GATEWAY}${queryParams}`;
        return await apiPost<CreateGatewayResult>(url, payload);
    };

    return handleApiRequest(requestFn, errorMessagePrefix);
}

/**
 * When we trigger Create Gateway API, Along side we trigger Request Gateway Installer
 * Requests a gateway installer for a client when the printer serial is not provided. If the printer serial is provided, the gateway is associated with the respective client.
 * This API call also handles gateway installer creation by CSEs and resellers for an associated company, with the association identified using the printer serial by Zeus.
 * @param coreDeviceThingName - The name of the gateway to be request.
 * @param printerSerial - An optional query parameter. If provided, it is assumed that the request is made by a reseller, and authorization is performed accordingly. See https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#reseller-vs-customer for full documentation.
 * @returns A promise that resolves to Creation of gateway based on two conditions
 *          1. A client requesting a gateway installer for their own company.
 *          2. A reseller/CSE requesting a gateway installer for a client/customer using the printer serial in the parameters.
 * @throws Will throw an error if the API request fails.
 */
export async function requestGatewayInstaller(
    coreDeviceThingName: string,
    printerSerial?: string
): Promise<JobIdDto> {
    const errorMessagePrefix = "Failed to request gateway installer";
    // Construct query parameters if printerSerial is provided
    const queryParams = buildQueryParams(printerSerial);

    const requestFn = async () => {
        const url = `${APIEndpoints.REQUEST_GATEWAY_INSTALLER}${queryParams}`;
        return await apiPost<JobIdDto>(url, {
            coreDeviceThingName,
        });
    };

    return handleApiRequest(requestFn, errorMessagePrefix);
}

/**
 * Removes a gateway installer. Optionally includes a printer serial in the request.
 *
 * @param coreDeviceThingName - The name of the gateway to be removed.
 * @param printerSerial - An optional query parameter. If provided, it indicates that the request is made by a reseller, and authorization is performed accordingly. For full documentation, see: https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#reseller-vs-customer
 * @returns A promise that resolves to null if the operation is successful.
 * @throws Will throw an error if the API request fails.
 */
export async function removeGatewayInstaller(
    coreDeviceThingName: string,
    printerSerial?: string
): Promise<null> {
    const errorMessagePrefix = "Failed to remove gateway installer";
    const queryParams = buildQueryParams(printerSerial, coreDeviceThingName);

    const removeGatewayUrl = `${APIEndpoints.REMOVE_GATEWAY}${queryParams}`;
    const requestFn = async () => {
        return await apiDelete<null>(removeGatewayUrl, {
            coreDeviceThingName,
        });
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}

/**
 * Decommissions a printer. Optionally includes a printer serial in the request.
 *
 * @param clientDeviceThingName - The name of the printer to be decommissioned.
 * @param printerSerial - An optional query parameter. If provided, it indicates that the request is made by a reseller, and authorization is handled accordingly. For details, refer to the Zeus API documentation: https://github.com/GrabCAD/zeus-gateway/blob/main/docs/control-api.md#reseller-vs-customer
 * @returns A promise that resolves to null if the operation is successful.
 * @throws Will throw an error if the API request fails.
 */
export async function decommissionPrinters(
    clientDeviceThingName: string,
    printerSerial?: string
): Promise<null> {
    const errorMessagePrefix = "Failed to remove decommission printer";
    const params = new URLSearchParams({
        clientDeviceThingName,
        ...(printerSerial && { printerSerial }),
    });

    const decommissionPrinterUrl = `${APIEndpoints.REMOVE_CLIENT}?${params.toString()}`;
    const requestFn = async () => {
        return await apiDelete<null>(decommissionPrinterUrl, {
            clientDeviceThingName,
        });
    };
    return handleApiRequest(requestFn, errorMessagePrefix);
}
