import React, { useContext } from "react";
import { Button, Header, Icon, Modal } from "grabcad-ui-elements";
import { ApplicationContext } from "../../components/ApplicationProvider";
import { RemovableEntityType } from "./GatewaysList";

interface Props {
    open: boolean;
    onClose: () => void;
    thingName: string;
    type: string;
    onRemoveConfirm: () => void;
}

/**
 * Confirm Modal for Remove Gateway or Printer.
 */

const ConfirmDialogModal: React.FC<Props> = ({
    open,
    onClose,
    type,
    thingName,
    onRemoveConfirm,
}: Props) => {
    const { t } = useContext(ApplicationContext);
    const title =
        type === RemovableEntityType.Analyze
            ? t("gateways.remove_confirm_modal.analyze_title", {
                  thingName,
              })
            : t("gateways.remove_confirm_modal.title", {
                  type,
                  thingName,
              });
    return (
        <Modal basic onClose={onClose} open={open} size="small">
            <Header icon="trash" content={title} />
            <Modal.Actions>
                <Button id="qa-company-removeAdminModal-cancel" secondary onClick={onClose}>
                    <Icon name="close" />
                    {t("gateways.remove_confirm_modal.cancel")}
                </Button>
                <Button id="qa-company-removeAdminModal-confirm" negative onClick={onRemoveConfirm}>
                    <Icon name="check" />
                    {t("gateways.remove_confirm_modal.confirm")}
                </Button>
            </Modal.Actions>
        </Modal>
    );
};
export default ConfirmDialogModal;
