import React, { useContext, useEffect, useRef, useState } from "react";
import styled, { css } from "styled-components";
import {
    ColorLabel,
    IBackgroundColor,
    Loader,
    Table,
    TableBody,
    TableCell,
    TableHeader,
    TableHeaderCell,
    TableRow,
    Popup,
    Button,
    GrabCADApplicationColors,
} from "grabcad-ui-elements";

import ConfirmDialogModal from "./ConfirmDialogModal";
import { ApplicationContext, TranslationFunction } from "../../components/ApplicationProvider";
import {
    GatewayInstallerStatus,
    IGateway,
    MaybeAssociatedClientDevice,
    ClientDeviceType,
} from "../../../../shared/src/types";
import { Notifier } from "../../view/Notifier";
import {
    decommissionPrinters,
    getGatewayInstallerUrl,
    removeGatewayInstaller,
} from "../../zeus-client/gatewaysApis";

enum InstallerContractStatus {
    Completed = "COMPLETED",
    Failed = "FAILED",
    Health = "HEALTHY",
    In_progress = "IN_PROGRESS",
    New = "NEW",
    Not_Requested = "NOT_REQUESTED",
    Unhealthy = "UNHEALTHY",
}

enum StatusColor {
    LABEL_NEUTRAL = "#858585",
}

export enum RemovableEntityType {
    Gateway = "gateway",
    Printer = "printer",
    Analyze = "analyze",
}

const StyledUlList = styled.ul`
    list-style-type: none;
    margin-left: -40px;
    display: grid;
    grid-template-columns: repeat(3, minmax(min-content, max-content));
    gap: 10px; /* Adjust gap as needed */
    align-items: start; /* Align items at the start of the grid */
`;

const ButtonWrapper = styled.div`
    grid-column: span 3; /* Span across all columns */
`;

const linkButtonStyle = {
    background: "none",
    color: "blue",
    border: "none",
    padding: 0,
    font: "inherit",
    cursor: "pointer",
    textDecoration: "underline",
};

const ListItemLabel = styled(ColorLabel)<ListItemLabelProps & IBackgroundColor>`
    font-size: 14px;
    margin-bottom: px;

    ${(props) =>
        props.hasPadding &&
        css`
            padding-right: 0px !important;
        `}
`;

const StyledListItem = styled.li`
    margin-bottom: 5px;
    cursor: default;
    transition: background-color 0.3s ease;
`;

const StyledTableCell = styled.div`
    color: ${(props: { status: string }) => getStatusColor(props?.status)};
`;

const getStatusColor = (status: string) => {
    switch (status) {
        case InstallerContractStatus.Unhealthy:
            return "Red";
        case InstallerContractStatus.New:
            return "Orange";
        default:
            return "Green";
    }
};

interface IGatewaysListProps {
    list: IGateway[] | undefined;
    newThingName?: string;
    orphanedClients: MaybeAssociatedClientDevice[];
    printerSerial?: string;
    removeThingByName: (
        setProperty: string,
        printerType: string | undefined,
        isPrinter: boolean
    ) => void;
}

interface ListItemLabelProps {
    hasPadding?: boolean;
}

interface IListProps {
    data: MaybeAssociatedClientDevice[];
    assignedPrinter: boolean;
}

interface IListItemProps {
    name: string;
    printerType: string | undefined;
    thingType: string | undefined;
    status: string;
    assignedPrinter: boolean;
}

const downloadFile = (url: string) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = url.split("/").slice(-1)[0];
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

const sortedGateways = (
    listOfGateways: IGateway[],
    sortedColumn: string | undefined,
    sortDirection: string | undefined
): IGateway[] => {
    const columnMappings: Record<string, keyof IGateway> = {
        printers: "associatedClientDevices",
        site: "site",
        status: "status",
    };

    if (sortedColumn && columnMappings[sortedColumn]) {
        const key = columnMappings[sortedColumn];

        // Default sort direction
        const direction: string = sortDirection || "ascending";

        const sortedList = [...listOfGateways];

        sortedList.sort((a, b) => {
            let comparison = 0;
            const valueA = a[key];
            const valueB = b[key];

            // Ensure valueA and valueB are defined before comparing
            if (valueA !== undefined && valueB !== undefined) {
                if (key === "associatedClientDevices") {
                    comparison = valueA.length - valueB.length;
                } else if (typeof valueA === "string" && typeof valueB === "string") {
                    comparison = valueA.localeCompare(valueB, undefined, {
                        sensitivity: "base",
                    });
                }
            } else {
                // Handle case where valueA or valueB is undefined
                comparison = valueA === undefined ? -1 : 1;
            }

            // Adjust comparison based on sort direction
            if (direction === "descending") {
                comparison = -comparison;
            }

            return comparison;
        });

        return sortedList;
    }
    return listOfGateways;
};

const getInstallerStatusMessage = (
    status: GatewayInstallerStatus,
    t: TranslationFunction
): void => {
    const messageMap = {
        IN_PROGRESS: "gateways.installer_not_ready_msg",
        COMPLETED: "gateways.installer_start_msg",
        FAILED: "gateways.installer_creation_failed_msg",
        NOT_REQUESTED: "gateways.certificate_already_exists",
        HEALTHY: "gateways.installer_healthy",
        NEW: "gateways.installer_new",
        UNHEALTHY: "gateways.installer_unhealthy",
    } as const;

    const messageKey = messageMap[status] || "gateways.installer_creation_failed_msg";

    Notifier[status === "COMPLETED" ? "success" : "fail"](t(messageKey));
};

const GatewaysList: React.FC<IGatewaysListProps> = ({
    list = [],
    newThingName,
    orphanedClients,
    printerSerial,
    removeThingByName,
}: IGatewaysListProps) => {
    const listOfGateways = list || [];
    const { t } = useContext(ApplicationContext);
    const [openModal, setOpenModal] = useState(false);
    const [loader, setLoader] = useState(false);
    const [loadingState, setLoadingState] = useState(false);
    const [isDeleteProcess, setIsDeleteProcess] = useState(false);
    const [confirmType, setConfirmType] = useState("");
    const [confirmSiteName, setConfirmSiteName] = useState("");
    const [confirmThingName, setConfirmThingName] = useState("");
    const [confirmPrinterType, setconfirmPrinterType] = useState<string | undefined>("");
    const [deleteProcessName, setDeleteProcessName] = useState("");
    const pollingIntervalRef = useRef<NodeJS.Timer | null>(null);
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascending" | "descending" | undefined>(
        undefined
    );
    const [downloadState, setDownloadState] = useState({ thingName: "", loader: false });

    const handleSort = (clickedColumn: string) => () => {
        setSortedColumn(clickedColumn);
        setSortDirection((prevDirection) =>
            prevDirection === "ascending" ? "descending" : "ascending"
        );
    };

    useEffect(() => {
        if (newThingName) {
            setLoadingState(true);

            const fetchInstallerUrl = async () => {
                try {
                    // By Passing printerSerial in getGatewayInstallerUrl
                    // We are allowing CSEs/Reseller to have access to fetch gateways, status and url
                    const { status } = await getGatewayInstallerUrl(newThingName, printerSerial);
                    if (
                        status === InstallerContractStatus.Completed ||
                        status === InstallerContractStatus.Failed ||
                        status === InstallerContractStatus.Unhealthy
                    ) {
                        clearInterval(pollingIntervalRef.current!);
                        setLoadingState(false);
                    }
                } catch (error) {
                    clearInterval(pollingIntervalRef.current!);
                    setLoadingState(false);
                    getInstallerStatusMessage(InstallerContractStatus.Failed, t);
                }
            };

            // Initial fetch
            void fetchInstallerUrl();

            // Polling interval (set for every 20 seconds)
            const interval: NodeJS.Timer = setInterval(fetchInstallerUrl, 20000);
            pollingIntervalRef.current = interval;

            return () => clearInterval(interval); // Cleanup
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newThingName]);

    const downloadGatewayInstaller = async (coreDeviceThingName: string) => {
        setDownloadState({ thingName: coreDeviceThingName, loader: true });
        try {
            // By Passing printerSerial in getGatewayInstallerUrl
            // We are allowing CSEs/Reseller to have access to fetch gateways, status and url
            // Also allowing them to download Gateways
            const jobStatusResult = await getGatewayInstallerUrl(
                coreDeviceThingName,
                printerSerial
            );
            setDownloadState({ thingName: coreDeviceThingName, loader: false });
            if (jobStatusResult?.status) {
                getInstallerStatusMessage(jobStatusResult.status, t);
                if (jobStatusResult.url) {
                    await downloadFile(jobStatusResult.url);
                }
            }
        } catch (error) {
            setDownloadState({ thingName: coreDeviceThingName, loader: false });
            getInstallerStatusMessage(InstallerContractStatus.Not_Requested, t);
        }
    };

    function onRemoveGateway(coreDeviceThingName: string) {
        if (!coreDeviceThingName) {
            return;
        }
        setIsDeleteProcess(true);
        setDeleteProcessName(coreDeviceThingName);
        // Allowing CSEs/Resellers to Remove client's Gateway installer by passing printerSerial in params
        removeGatewayInstaller(coreDeviceThingName, printerSerial)
            .then(() => {
                setCheckIfAndClearState(coreDeviceThingName, "", false);
                Notifier.success(t("gateways.remove_gateway_success"));
            })
            .catch(() => {
                clearState();
                Notifier.fail(t("gateways.remove_gateway_error"));
            });
    }

    function onRemovePrinter(thingName: string, printerType: string | undefined) {
        if (!thingName) {
            return;
        }
        setLoader(true);
        // Allowing CSEs/Resellers to decommission  client's Printers by passing printerSerial in params
        decommissionPrinters(thingName, printerSerial)
            .then(() => {
                setLoader(false);
                removeThingByName(thingName, printerType, true);
                Notifier.success(t("gateways.remove_printer_success"));
            })
            .catch(() => {
                setLoader(false);
                Notifier.fail(t("gateways.remove_printer_error"));
            });
    }

    function setCheckIfAndClearState(
        coreDeviceThingName: string,
        printerType: string,
        isPrinter: boolean
    ) {
        removeThingByName(coreDeviceThingName, printerType, isPrinter);
        clearState();
    }

    function clearState() {
        setIsDeleteProcess(false);
        setDeleteProcessName("");
    }

    function removeGateway(thingName: string) {
        const findSiteName = list.find((e) => e.coreDeviceThingName === thingName);
        setConfirmThingName(thingName);
        setConfirmType(RemovableEntityType.Gateway);
        setConfirmSiteName(findSiteName?.site || "");
        setOpenModal(true);
    }

    function removePrinters(thingName: string, printerType: string | undefined) {
        const type = !!printerType ? RemovableEntityType.Printer : RemovableEntityType.Analyze;
        setConfirmThingName(thingName);
        setconfirmPrinterType(printerType);
        setConfirmType(type);
        setOpenModal(true);
    }

    const List: React.FC<IListProps> = ({ data, assignedPrinter }) => {
        const [showAll, setShowAll] = useState(false);

        const toggleShowAll = () => {
            setShowAll(!showAll);
        };

        const dataList: MaybeAssociatedClientDevice[] = data as MaybeAssociatedClientDevice[];
        return (
            <>
                {data?.length > 0 && (
                    <>
                        <StyledUlList>
                            {dataList
                                .slice(0, showAll ? dataList.length : 3)
                                .map((thing, index) => (
                                    <ListItem
                                        key={index}
                                        name={thing?.thingName}
                                        printerType={thing?.printerType}
                                        thingType={thing?.thingType}
                                        status={thing?.status ?? ""}
                                        assignedPrinter={assignedPrinter}
                                    />
                                ))}

                            {dataList.length > 3 && (
                                <ButtonWrapper>
                                    <Button onClick={toggleShowAll}>
                                        {showAll
                                            ? t("gateways.show_less")
                                            : `+${dataList.length - 3} ${t("gateways.more")}`}
                                    </Button>
                                </ButtonWrapper>
                            )}
                        </StyledUlList>
                    </>
                )}
            </>
        );
    };

    const ListItem: React.FC<IListItemProps> = ({
        name,
        printerType, // Type of printer (e.g., J55, FDM, etc.)
        thingType, // Type of client or entity (e.g., whether the entity is a printer or an analyzer)
        status,
        assignedPrinter,
    }) => {
        const statusMap: Record<string, { backgroundColor: string; displayStatus: string }> = {
            ONLINE: {
                backgroundColor: GrabCADApplicationColors.GC_SUCCESS_SEMANTIC,
                displayStatus: t("gateways.status.online"),
            },
            NEW: {
                backgroundColor: GrabCADApplicationColors.GC_PRINT,
                displayStatus: t("gateways.status.new"),
            },
            OFFLINE: {
                backgroundColor: StatusColor.LABEL_NEUTRAL,
                displayStatus: t("gateways.status.offline"),
            },
        };

        const { backgroundColor, displayStatus } = statusMap[status] || {
            backgroundColor: StatusColor.LABEL_NEUTRAL,
            displayStatus: null,
        };

        const contentType =
            !!printerType || thingType === ClientDeviceType.Printer ? printerType + " - " : "";
        const commonContent = (
            <ListItemLabel color={backgroundColor} hasPadding={!loader && assignedPrinter}>
                {`${contentType + name}`}&nbsp;&nbsp;
                {assignedPrinter &&
                    (loader && name === confirmThingName ? (
                        <span>
                            <Loader active inline size="mini" />
                        </span>
                    ) : (
                        <i
                            onClick={() => {
                                removePrinters(name, printerType);
                            }}
                            className="delete icon danger"
                        />
                    ))}
            </ListItemLabel>
        );

        return (
            <StyledListItem key={name}>
                {displayStatus ? (
                    <Popup
                        trigger={commonContent}
                        content={displayStatus}
                        position="right center"
                        inverted
                    />
                ) : (
                    commonContent
                )}
            </StyledListItem>
        );
    };

    return (
        <>
            <Table sortable>
                <TableHeader>
                    <TableRow>
                        <TableHeaderCell
                            sorted={sortedColumn === "status" ? sortDirection : undefined}
                            onClick={handleSort("status")}
                        >
                            {t("gateways.gateways_status_header")}
                        </TableHeaderCell>
                        <TableHeaderCell
                            sorted={sortedColumn === "site" ? sortDirection : undefined}
                            onClick={handleSort("site")}
                        >
                            {t("gateways.gateways_site_header")}
                        </TableHeaderCell>
                        <TableHeaderCell
                            colSpan={2}
                            sorted={sortedColumn === "printers" ? sortDirection : undefined}
                            onClick={handleSort("printers")}
                        >
                            {t("gateways.gateways_clients_header")}
                        </TableHeaderCell>
                    </TableRow>
                </TableHeader>
                <TableBody>
                    {orphanedClients?.length > 0 && (
                        <TableRow>
                            <TableCell>{t("gateways.no_gateway")}</TableCell>
                            <TableCell>{t("gateways.gateways_no_site")}</TableCell>
                            <TableCell>
                                <List data={orphanedClients} assignedPrinter={false} />
                            </TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    )}
                    {sortedGateways(listOfGateways, sortedColumn, sortDirection).map(
                        (gateway: IGateway, index: number) => (
                            <TableRow key={index}>
                                <TableCell className={`table-header-status`}>
                                    {loadingState &&
                                    gateway.coreDeviceThingName === newThingName ? (
                                        <h4>
                                            <Loader active inline /> &nbsp;&nbsp;
                                            {t("gateways.building_installer")}
                                        </h4>
                                    ) : (
                                        <StyledTableCell status={gateway.status}>
                                            <span>{gateway.status}</span> &nbsp;&nbsp;
                                            {gateway.status === "NEW" && (
                                                <button
                                                    style={linkButtonStyle}
                                                    onClick={() => {
                                                        void downloadGatewayInstaller(
                                                            gateway.coreDeviceThingName
                                                        );
                                                    }}
                                                >
                                                    {downloadState.loader &&
                                                    gateway.coreDeviceThingName ===
                                                        downloadState.thingName ? (
                                                        <span>
                                                            <Loader active inline size="mini" />
                                                        </span>
                                                    ) : (
                                                        t("gateways.download_installer_text")
                                                    )}
                                                </button>
                                            )}
                                        </StyledTableCell>
                                    )}
                                </TableCell>
                                <TableCell className={`table-header-site`}>
                                    {gateway.site}
                                </TableCell>
                                <TableCell className={`table-header-printers`}>
                                    <List
                                        data={gateway.associatedClientDevices}
                                        assignedPrinter={true}
                                    />
                                </TableCell>
                                <TableCell className={`table-header-${"actions"}`}>
                                    {!(
                                        loadingState && gateway.coreDeviceThingName === newThingName
                                    ) && gateway?.associatedClientDevices?.length < 1 ? (
                                        <Button
                                            negative
                                            color="red"
                                            id={index}
                                            loading={
                                                deleteProcessName === gateway.coreDeviceThingName &&
                                                isDeleteProcess
                                            }
                                            disabled={
                                                deleteProcessName === gateway.coreDeviceThingName &&
                                                isDeleteProcess
                                            }
                                            onClick={() => {
                                                removeGateway(gateway.coreDeviceThingName);
                                            }}
                                        >
                                            {t("gateways.remove_gateway")}
                                        </Button>
                                    ) : null}
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
            {openModal && (
                <ConfirmDialogModal
                    open={openModal}
                    onClose={() => {
                        setOpenModal(false);
                    }}
                    onRemoveConfirm={() => {
                        setOpenModal(false);
                        if (confirmType === RemovableEntityType.Gateway) {
                            onRemoveGateway(confirmThingName);
                        }
                        if (
                            confirmType === RemovableEntityType.Printer ||
                            confirmType === RemovableEntityType.Analyze
                        ) {
                            onRemovePrinter(confirmThingName, confirmPrinterType);
                        }
                    }}
                    thingName={
                        confirmType === RemovableEntityType.Printer ||
                        confirmType === RemovableEntityType.Analyze
                            ? confirmThingName
                            : confirmSiteName
                    }
                    type={confirmType}
                />
            )}
        </>
    );
};
export default GatewaysList;
