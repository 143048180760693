import gql from "graphql-tag";
import type {
    CompanyAccessOption,
    CompanySettings,
    CompanyTOSState,
    CustomerInfo,
    OperatingSchedule,
    ResellerInfo,
    User,
    WorkbenchAccount,
} from "../types";
import { CompanyFragments } from "../Fragments/Company";
import { useLazyQuery } from "@apollo/client";
import { useContext } from "react";
import { UserContext } from "../../components/User/UserProvider";
import { Notifier } from "../../view/Notifier";
import { useQueryWithCatch } from "../../utils/hooks/useQueryWithCatch";

export const GET_COMPANY_AS_ADMIN = gql`
    query company($id: Int) {
        company(id: $id) {
            id
            tosState
            ...adminEditableFields
            customerInfo {
                accountNumber
                name
                phoneNumber
                email
            }
            ...operatingSchedule
        }
    }
    ${CompanyFragments.adminEditableFields}
    ${CompanyFragments.operatingSchedule}
`;

export const GET_COMPANY_APPS = gql`
    query company($id: Int) {
        company(id: $id) {
            id
            applications {
                id
                name
                visible
                dateDeleted
                applicationType {
                    id
                    description
                    roles {
                        id
                        name
                    }
                }
            }
        }
    }
`;

export interface CompanyAsAdmin {
    id: number;
    name: string;
    urlPrefix?: string;
    tosState?: CompanyTOSState;
    analyzeServerAddress?: string;
    admins: User[];
    settings: CompanySettings;
    customerInfo?: CustomerInfo;
    operatingSchedule?: OperatingSchedule;
}
export interface GetCompanyQueryResult {
    company: CompanyAsAdmin;
}

export function useCompanyAsAdminQuery() {
    const { companyId } = useContext(UserContext);
    const [fetch, { data, loading, error, refetch }] =
        useLazyQuery<GetCompanyQueryResult>(GET_COMPANY_AS_ADMIN);
    if (error) {
        Notifier.error(error);
    }
    if (!companyId) {
        // This enables us to call the hook unconditionally in Components shared by Global Admins,
        // which only display company info for Company Admins. EG: <TopNavbarWrapper>
        return {
            companyLoading: false,
        };
    }

    if (!data && !loading && !error) {
        void fetch();
    }

    return {
        companyLoading: loading,
        company: data?.company,
        companyRefetch: refetch,
    };
}

export const GET_COMPANY_GLOBAL_ADMIN = gql`
    query company($id: Int!) {
        company(id: $id) {
            id
            urlPrefix
            internal
            ...adminEditableFields
            customerInfo {
                accountNumber
                name
                phoneNumber
                email
            }
            resellerInfo {
                name
                phoneNumber
                email
                contactPerson
                accountNumber
            }
            accessOptions {
                id
                url
                state
                groups {
                    id
                    name
                }
                companyWithSameUrl {
                    id
                    name
                }
            }
            workbenchAccounts {
                id
                name
            }
            ...operatingSchedule
        }
    }
    ${CompanyFragments.adminEditableFields}
    ${CompanyFragments.operatingSchedule}
`;
export interface CompanyAsGlobalAdmin extends CompanyAsAdmin {
    internal: boolean;
    urlPrefix?: string;
    resellerInfo?: ResellerInfo;
    accessOptions: CompanyAccessOption[];
    workbenchAccounts: WorkbenchAccount[];
}

export interface GetCompanyQueryAsGlobalAdminVariables {
    id: number;
}
export interface GetCompanyQueryAsGLobalAdminResult {
    company: CompanyAsGlobalAdmin;
}

export function useCompanyAsGlobalAdminQuery(id: number) {
    const { data, loading, refetch } = useQueryWithCatch<
        GetCompanyQueryAsGLobalAdminResult,
        GetCompanyQueryAsGlobalAdminVariables
    >(GET_COMPANY_GLOBAL_ADMIN, { variables: { id } });

    return {
        companyLoading: loading,
        company: data?.company,
        companyRefetch: refetch,
    };
}
