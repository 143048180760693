import { FocusEvent, ChangeEvent, FormEvent, useState } from "react";
import { IScreenProps } from "../Screen";
import { Button, Form, FormField, Loader } from "grabcad-ui-elements";
import { Checkbox, Input, TextArea } from "semantic-ui-react";
import { Breadcrumbs } from "../../view/Navigation/BreadCrumbs";
import { gql, useMutation, type MutationFunction } from "@apollo/client";
import { Notifier } from "../../view/Notifier";
import type { match } from "react-router-dom";
import {
    useCompanyAsGlobalAdminQuery,
    type WorkbenchAccount,
    type CreateCompanyFromWbArgs,
    type UpdateCompanyFromWbArgs,
} from "../../graphql";

interface UpdateCompanyFromWorkbenchProps extends IScreenProps {
    match: match<{ companyId?: string }>;
}

const CREATE_COMPANY_FROM_WB = gql`
    mutation createCompanyFromWb(
        $name: String
        $accountIds: [Int!]!
        $copyAccountName: Boolean
        $notifyUsers: Boolean
    ) {
        createCompanyFromWb(
            name: $name
            accountIds: $accountIds
            copyAccountName: $copyAccountName
            notifyUsers: $notifyUsers
        ) {
            id
            name
            workbenchAccounts {
                id
                name
            }
        }
    }
`;

const UPDATE_COMPANY_FROM_WB = gql`
    mutation updateCompanyFromWb($id: Int!, $accountIds: [Int!]!, $notifyUsers: Boolean) {
        updateCompanyFromWb(id: $id, accountIds: $accountIds, notifyUsers: $notifyUsers) {
            id
            name
            workbenchAccounts {
                id
                name
            }
        }
    }
`;

export type CreateCompanyMutationResult = {
    createCompanyFromWb: {
        id: number;
        name: string;
    };
};

export type UpdateCompanyMutationResult = {
    updateCompanyFromWb: {
        id: number;
        name: string;
    };
};

type CreateCompanyMutationProps = {
    mode: "create";
    mutate: MutationFunction<CreateCompanyMutationResult, CreateCompanyFromWbArgs>;
};

type UpdateCompanyMutationProps = {
    mode: "update";
    mutate: MutationFunction<UpdateCompanyMutationResult, UpdateCompanyFromWbArgs>;
    companyId: number;
    companyName: string;
    workbenchAccounts: WorkbenchAccount[];
};

type MigrateCompanyFromWorkbenchProps = CreateCompanyMutationProps | UpdateCompanyMutationProps;

export const UpdateCompanyFromWorkbench = (props: UpdateCompanyFromWorkbenchProps) => {
    const companyId = Number(props.match.params.companyId);
    const { company } = useCompanyAsGlobalAdminQuery(companyId);

    const [mutate, { loading }] = useMutation<UpdateCompanyMutationResult, UpdateCompanyFromWbArgs>(
        UPDATE_COMPANY_FROM_WB,
        {
            update: (_cache, { data }) => {
                if (!data) {
                    Notifier.error(new Error("Something went wrong."));
                    return;
                }

                Notifier.success("Successfully updated company.");
            },
            onError: (err) => {
                Notifier.error(err);
            },
        }
    );

    if (!company?.name || loading) {
        return <Loader active size="large" />;
    }

    return (
        <MigrateCompanyFromWorkbench
            companyId={companyId}
            companyName={company.name}
            mutate={mutate}
            mode="update"
            workbenchAccounts={company.workbenchAccounts}
        />
    );
};

export const CreateCompanyFromWorkbench = (props: IScreenProps) => {
    const [mutate, { loading }] = useMutation<CreateCompanyMutationResult, CreateCompanyFromWbArgs>(
        CREATE_COMPANY_FROM_WB,
        {
            update: (_cache, { data }) => {
                if (!data) {
                    Notifier.error(new Error("Something went wrong."));
                    return;
                }

                Notifier.success("Successfully created company.");

                props.history.push(`/company/wb/${data.createCompanyFromWb.id}`);
            },
            onError: (err) => {
                Notifier.error(err);
            },
        }
    );

    if (loading) {
        return <Loader active size="large" />;
    }

    return <MigrateCompanyFromWorkbench mutate={mutate} mode="create" />;
};

export const MigrateCompanyFromWorkbench = (props: MigrateCompanyFromWorkbenchProps) => {
    const [useWorkbenchCompanyName, setUseWorkbenchCompanyName] = useState<boolean>(false);
    const [submitClicked, setSubmitClicked] = useState<boolean>(false);
    const [companyName, setCompanyName] = useState<string>(
        props.mode !== "create" ? props.companyName : ""
    );

    const resetErrorClass = (target: HTMLElement) => {
        target.closest(".field")!.classList.remove("error");
    };

    const renderBreadcrumbs = () => {
        if (props.mode === "create") {
            return <Breadcrumbs sections={[{ label: "Create Company from Workbench" }]} />;
        }

        return <Breadcrumbs sections={[{ label: "Update Company from Workbench" }]} />;
    };

    const renderCompanyLink = () => {
        if (props.mode === "create") {
            return <></>;
        }

        return (
            <div style={{ marginBottom: "20px" }}>
                <h3>Control Company:</h3>
                <a href={`/company/${props.companyId}`}>{props.companyName}</a>
            </div>
        );
    };

    const renderWorkbenchAccounts = () => {
        if (props.mode === "create") {
            return <></>;
        }

        return (
            <div>
                <h3>Workbench Accounts:</h3>
                {props.workbenchAccounts.map(({ id, name }) => {
                    if (name) {
                        return (
                            <div key={id}>
                                {name} ({id})
                            </div>
                        );
                    }

                    return (
                        <div key={id}>
                            <i>Deleted</i> ({id})
                        </div>
                    );
                })}
            </div>
        );
    };

    return (
        <>
            {renderBreadcrumbs()}
            <Form
                noValidate
                style={{ maxWidth: 550, paddingBottom: 25 }}
                onSubmit={(e: FormEvent) => {
                    e.preventDefault();

                    const target = e.target as HTMLFormElement;
                    const isValid = target.checkValidity();

                    if (!isValid) {
                        const invalidFields = target.querySelectorAll(":invalid");
                        invalidFields.forEach((f) => {
                            f.closest(".field")!.classList.add("error");
                        });
                        return;
                    }

                    const accountIds = target.accountIds.value.split(",").map((id: string) => +id);

                    setSubmitClicked(true);

                    if (props.mode === "create") {
                        void props.mutate({
                            variables: {
                                name: target.companyName.value,
                                accountIds,
                                copyAccountName: target.copyAccountName.checked,
                                notifyUsers: target.notifyUsers.checked,
                            },
                        });
                    } else {
                        void props.mutate({
                            variables: {
                                id: props.companyId,
                                accountIds,
                                notifyUsers: target.notifyUsers.checked,
                            },
                        });
                    }
                }}
            >
                <div style={{ marginBottom: 25 }}>
                    <FormField id="qa-wb-input-account-ids">
                        <label>
                            Enter Workbench account numbers
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </label>
                        <TextArea
                            placeholder="Comma separated Workbench accounts IDs"
                            name="accountIds"
                            required
                            onChange={(e: ChangeEvent) => {
                                resetErrorClass(e.target as HTMLFormElement);
                            }}
                            onBlur={(e: FocusEvent) => {
                                const target = e.target as HTMLTextAreaElement;
                                const parts = target.value.split(",");
                                const isValid = parts.every(
                                    (id) => id !== "" && !Number.isNaN(+id)
                                );

                                target.setCustomValidity(isValid ? "" : "Invalid Workbench ID(s)");
                            }}
                        />
                    </FormField>
                    <Checkbox
                        label="Copy first Workbench Account name"
                        name="copyAccountName"
                        disabled={props.mode !== "create"}
                        onClick={() => setUseWorkbenchCompanyName(!useWorkbenchCompanyName)}
                    />
                </div>
                <div style={{ marginBottom: 25 }}>
                    <FormField
                        disabled={props.mode !== "create" || useWorkbenchCompanyName}
                        id="qa-wb-input-company-name"
                    >
                        <label>
                            Enter Company name
                            <span style={{ color: "red" }}>&nbsp;*</span>
                        </label>
                        <Input
                            name="companyName"
                            required={!useWorkbenchCompanyName}
                            value={companyName}
                            disabled={props.mode !== "create"}
                            onChange={(e, data) => {
                                setCompanyName(data.value);
                                resetErrorClass(e.target);
                            }}
                        />
                    </FormField>
                </div>
                <FormField key="buttons">
                    <Checkbox
                        label="Notify company users after Workbench accounts are migrated"
                        name="notifyUsers"
                        defaultChecked
                    />
                    <Button
                        id="qa-wb-submit"
                        type="submit"
                        className="primary right floated"
                        disabled={submitClicked}
                    >
                        Submit
                    </Button>
                </FormField>
            </Form>
            {renderCompanyLink()}
            {renderWorkbenchAccounts()}
        </>
    );
};
